import {
  Button,
  Col,
  Drawer,
  Pagination,
  Row,
  Spin,
  Tabs,
  Tag,
  message,
  Form,
  Space,
  Popconfirm,
  Tooltip,
} from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./comTable/index.less";
import {
  purchaseOrder,
  orderCancel,
  batchShipment,
  splitOrder,
} from "./order.reducer";
import SearchFor from "./comTable/searchFor";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import { useParamGeneral } from "components/useParamGenera";
import { useEnumValue } from "common/enum/use-enum-value";
import { internationalList } from "components/IntelFormText";
import OrderDetails from "pages/procure/purchase-order/order-details";
import { Image } from "antd";
import "./index.less";
import tstLogo from "../../../assets/logo-tst.png";
import OrderShipModal from "../components/order-ship-modal";
import imgNoData from "../../../assets/nodata.svg";
import { ModalForm, ProFormTextArea } from "@ant-design/pro-form";
import { fetchOrderCancelList } from "pages/procure/order-cancel/order-cancel.reducer";
import PayDetailDialog from "./payDetailDialog";
import ImportDataDialog from "upload/import-data-dialog";
import SelectSearch from "components/select-search";
import { getDate } from "components/tool";
import { Storage } from "utils/storage-util";
import { goTop } from "layouts/page-wraper/list-view-page";
import menu from "layouts/menu";
import ExportDataDialog from "export/export-data-dialog";
import { FilterCriteria } from "components/model/share.model";
// tab数据
export const orderTypeData = [
  {
    label: "全部",
    value: "All",
  },
  {
    label: "待付款",
    value: "ToBePaid",
  },
  {
    label: "待发货",
    value: "Paid",
  },
  {
    label: "出库中",
    value: "Shipping",
  },
  {
    label: "已发货",
    value: "Shipped",
  },
  {
    label: "已收货",
    value: "Received",
  },
  {
    label: "已完成",
    value: "Finished",
  },
  {
    label: "已取消",
    value: "Cancelled",
  },
];

const Table = (props: any) => {
  const { navigate, location } = props;
  const { getEnumValueDesc, convertEnumToOptionType } = useEnumValue();
  const [tableData, setTableData]: any = useState([]);
  const [paginationTotal, setPaginationTotal]: any = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrdertype] = useState("All");
  const { currency } = useParamGeneral();
  const [isShip, setIsShip] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const [form] = Form.useForm<{}>();
  let [toCancelDialog, settoCancelDialog] = useState<any>(false);
  const [orderId, setOrderId] = useState<any>();
  const [closeDesc, setCloseDesc] = useState("");
  const [openPayDetail, setOpenPayDetail] = useState(false);
  let [key, setKey] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [forceCancel, setForceCancel] = useState(false);
  const [forceCancelObj, setForceCancelObj] = useState({});
  const [activeKey, setActiveKey] = useState("0");
  const [items, setItems] = useState<any>([]);
  const [tabIdentifying, setTabIdentifying] = useState(0);
  const pathName: any = window.location.pathname;
  // const saleOrderText = pathName.split('sale-order').length > 1; //销售订单
  const purchaseOrderText = pathName.split("purchase-order").length > 1; //采购订单

  const downloadColumns = [{
    title: '发货日期',
    dataIndex: 'shipTime',
  }, {
    title: '授权码',
    dataIndex: 'buyerCode',
  }, {
    title: '客户名称',
    dataIndex: 'consigneeName',
  }, {
    title: '下单日期',
    dataIndex: 'bookTime',
  }, {
    title: '扣款日期',
    dataIndex: 'payTime',
  }, {
    title: '订单编号',
    dataIndex: 'orderNo',
  }, {
    title: '订单状态',
    dataIndex: 'orderState',
    valueType: 'purchaseOrderStatus',
  }, {
    title: '订单行状态',
    dataIndex: 'status',
    valueType: 'purchaseOrderLineStatus',
  }, {
    title: '款号',
    dataIndex: 'skuCode',
  }, {
    title: '商品名称',
    dataIndex: 'productName',
  }, {
    title: '规格名称',
    dataIndex: 'specName_k',
  }, {
    title: '规格值',
    dataIndex: 'specName_v',
  }, {
    title: '购买数量',
    dataIndex: 'quantity',
  }, {
    title: '单价',
    dataIndex: 'actualPrice',
  }, {
    title: '总价',
    dataIndex: 'subtotal',
  }, {
    title: '收货人信息（姓名、号码、地址）',
    dataIndex: 'address',
  }]

  //导出
  const downloadAllEntities = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : tableData;
    exportOrderData.length > 0 && exportOrderData.forEach((res: any) => {
      const address = res.address.provinceName + res.address.cityName + res.address.districtName + res.address.address + ';' + res.address.consigneeName + ',' + res.address.mobile + '。'
      res.lines.length > 0 && res.lines.forEach((item: any) => {
        let arr: any = {
          shipTime: item.shipTime,
          buyerCode: res.buyer.buyerCode,
          consigneeName: res.address.consigneeName,
          bookTime: res.bookTime,
          payTime: res.payTime,
          orderNo: res.orderNo,
          orderState: res.orderState,
          status: item.status,
          skuCode: item.skuCode,
          productName: item.productName,
          specName_k: item.specName && item.specName[0].k,
          specName_v: item.specName && item.specName[0].v,
          quantity: item.quantity - item.cancelledQuantity,
          actualPrice: item.actualPrice,
          subtotal: (item.quantity - item.cancelledQuantity) * item.actualPrice,
          address: address,
        };
        orderData.push(arr);
      });
    });
    return orderData;
  }

  //下载全部回调
  const exportData = async (downLoadText?: string) => {
    let page = 0;
    let size = 1000;
    let allData: any[] = [];

    do {
      const params: any = {
        ...tableParm,
        page,
        size,
      };

      //去除值为空的key
      const newFilterObj: FilterCriteria = {};
      const objArr = Object.keys(params);
      objArr.forEach((key, index) => {
        const val = params[key];
        if (val !== '') {
          newFilterObj[key] = val;
        };
      });

      const getData: any = await purchaseOrder(newFilterObj);
      let newData = [];
      newData = downloadAllEntities ? downloadAllEntities(getData.data) : getData.data;
      allData = allData.concat(newData);
      ++page;

    } while ((page - 1) * size < paginationTotal);
    return allData
  }

  // 详情弹框
  const [detailShow, SetDetailShow] = useState(false);
  // 行id
  const [detailId, SetDetailId] = useState<string>("");

  // 行id
  const [detailRow, SetDetailRow] = useState<string>("");
  // 请求参数
  const [tableParm, setTableParm] = useState<any>({
    size: 10,
    page: 0,
    sort: "bookTime,desc",
    "bookTime.greaterThanOrEqual": getDate(-30),
    "type.equals": !!purchaseOrderText ? "Purchase" : "Normal",
  });

  useEffect(() => {
    if (tabIdentifying !== 0) {
      const tabsData = JSON.parse(Storage.session.get("tabsData") || "[]");
      const newTbsData = tabsData.concat().map((item: any) => {
        return item.params.url === location.pathname
          ? {
            ...item,
            params: {
              ...item.params,
              searchParams: tableParm,
            },
          }
          : item;
      });
      Storage.session.set("tabsData", JSON.stringify(newTbsData));
      getTableData(tableParm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParm, location]);

  // 获取table数据
  const getTableData = (data: any) => {
    setLoading(true);
    purchaseOrder(data)
      .then((e) => {
        setPaginationTotal(e["headers"]["x-total-count"] || 0);
        let list = [...e.data];
        list.forEach((item: any) => {
          let total = 0;
          item.lines.forEach((subItem: any) => {
            total += subItem.quantity || 0;
          });
          item.totalQuantity = total;
        });
        setTableData(list);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // tab切换搜索
  const tabSearch = (res: string) => {
    setOrdertype(res);
    let newParam = {
      ...tableParm,
      queryType: res,
    };
    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...tableParm,
      ...newParam,
      page: 0,
      size: 10,
    });
  };

  // 搜索条件
  const onSearch = (data: any) => {
    let newParam = {
      queryType: orderType,
      ...tableParm,
      ...data,
    };
    Object.keys(newParam).map((item) => {
      if (!newParam[item] && item !== "page") {
        delete newParam[item];
      }
      return true;
    });
    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...newParam,
      page: 0,
      size: 10,
    });
  };

  // 展开收缩
  const setDataIsOpen = (index: number, isOpen: boolean) => {
    tableData[index].isOpen = !isOpen;
    setTableData([...tableData]);
  };

  // 页码改变
  const pageOnChange = (page: number, pageSize: number) => {
    let pag = page - 1;
    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...tableParm,
      page: pag,
      size: pageSize,
    });
  };

  // 订单审核
  // const auditPurchaseOrderPost = (id: number) => {
  //   auditPurchaseOrder(id).then(e => {
  //     message.success('审核成功')
  //     getTableData(tableParm)
  //   }).catch(() => {
  //     message.error('审核失败')
  //   })
  // }

  // 打开取消弹框
  const openCancelDialog = (id: any) => {
    setOrderId(id);
    settoCancelDialog(true);
  };

  const copyToClipboard = (str: string) => {
    message.success("复制成功");
    return navigator?.clipboard?.writeText(str).catch((err) => {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 },
    },
  };

  const orderRead = (sonItem: any) => (
    <Row className="order-row">
      <Col className="col" span={4}>
        <div className="flexCss">
          <div className="flex-left">
            <Image
              width={60}
              src={
                !!sonItem.picture
                  ? internationalList(sonItem.picture || "")
                  : tstLogo
              }
            />
          </div>
          <div className="flex-right">
            <p className="textline2">
              {internationalList(sonItem.productName || "")}
            </p>
            <p>SKU编码：{sonItem.skuCode}</p>
          </div>
        </div>
      </Col>
      <Col className="col" span={2}>
        {sonItem.activityName}
      </Col>
      <Col className="col" span={1}>
        {sonItem.lineType === 'FREE_GIFT' && <Tag color="green">赠品</Tag>}
      </Col>
      <Col className="col" span={2}>
        <p>
          {" "}
          {sonItem?.specName?.map((v: any) => {
            return (
              <>
                <p>
                  {v.k}: {v.v}
                </p>
              </>
            );
          })}
          {sonItem.blueStarProduct && <Tag color="green">蓝星商品</Tag>}
        </p>
      </Col>
      <Col className="col" span={2}>
        {currency?.symbol + " " + sonItem.memberPrice + "/" + sonItem.quantity}
      </Col>
      <Col className="col" span={2}>
        {sonItem.actualPrice + "/" + sonItem.quantity}
      </Col>
      <Col className="col" span={1}>
        {currency?.symbol + " " + sonItem.subtotal}
      </Col>
      <Col className="col" span={1}>
        {sonItem.earnPoint}
      </Col>
      <Col className="col" span={1}>
        {sonItem?.shippedQuantity}
      </Col>
      <Col className="col" span={1}>
        {sonItem?.cancelledQuantity}
      </Col>
      <Col className="col" span={2}>
        {getEnumValueDesc("purchaseOrderLineStatus", sonItem.status || "")}
        {sonItem.status === 'PART_SHIPPED' && <p>
          <Popconfirm
            title={"你确定要拆单吗？"}
            icon={<WarningOutlined />}
            onConfirm={() => {
              splitOrder(sonItem.id).then(e => {
                message.success('操作成功');
                getTableData(tableParm);
              }).catch(error => {
                message.error('拆单失败，请联系管理员！')
              });
            }}
          >
            <Tooltip placement="leftTop" title="拆单完成后将会拆成 ‘未发货’ 和 ‘已发货’ 两个订单行" key="splitOrder">
              <Button
                ghost
                type="primary"
                size="small"
                style={{ marginLeft: "10px" }}
              >
                拆单
              </Button>
            </Tooltip>
          </Popconfirm>
        </p>
        }
      </Col>
      <Col className="col" span={2}>
        {sonItem?.shipTime}
      </Col>
      <Col className="col" span={2}>
        {sonItem.serviceState || "-"} / {sonItem.quantity}{" "}
      </Col>
      <Col className="col" span={2}>
        {/* <p><Button ghost type="primary" size="small">确认发货</Button></p> */}
      </Col>
    </Row>
  );

  const uploadColumns = [
    {
      title: "订单号",
      dataIndex: "orderSn",
      valueType: "text",
      width: 100,
    },
    {
      title: "快递单号",
      dataIndex: "expressNo",
      width: 100,
      valueType: "text",
    },
    {
      title: "快递编码",
      dataIndex: "expressCode",
      width: 100,
      valueType: "text",
    },
    {
      title: "快递名称",
      dataIndex: "expressName",
      width: 100,
      valueType: "text",
    },
    {
      title: "skuCode",
      dataIndex: "skuCode",
      width: 100,
      valueType: "text",
    },
    {
      title: "数量",
      dataIndex: "quantity",
      width: 100,
      valueType: "text",
    },
  ];

  const TabsOnChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey + "");
    const url = items
      .concat()
      .filter((e: any) => e.key === newActiveKey + "")[0].params.url;
    navigate && navigate(url);
  };

  const searchActiveKey = (newPanes: any) => {
    const pathname = location.pathname;
    const key =
      newPanes.concat().filter((e: any) => e.params.url === pathname)?.[0]
        ?.key || "0";
    setActiveKey(key);
  };

  const add = (params: any) => {
    const tabsData = JSON.parse(Storage.session.get("tabsData") || "[]");
    const newPanes = tabsData && tabsData.length > 0 ? tabsData : [];
    const newActiveKey = newPanes.length;
    setItems(newPanes);
    searchActiveKey(newPanes);
    if (
      newPanes.concat().filter((item: any) => item.params.url === params.url)
        .length === 0
    ) {
      newPanes.push({
        label: params.title,
        children: "",
        key: newActiveKey + "",
        params: params,
      });
      setItems(newPanes);
      searchActiveKey(newPanes);
      Storage.session.set("tabsData", JSON.stringify(newPanes));
    } else {
      const filterParams =
        newPanes
          .concat()
          .filter((item: any) => item.params.url === params.url)?.[0]?.params
          ?.searchParams || {};
      setTabIdentifying(new Date().getTime());
      filterParams &&
        setTableParm({
          ...tableParm,
          ...filterParams,
        });
    }
  };

  const remove = (targetKey: string) => {
    const newItems = items.concat().filter((e: any) => e.key !== targetKey);
    setItems(newItems);
    Storage.session.set("tabsData", JSON.stringify(newItems));
  };

  const onEdit = (targetKey: any, action: any) => {
    if (action === "add") {
      // add();
    } else {
      remove(targetKey);
    }
  };
  // 导出
  // const goExport = () => {
  //   let newParm = {
  //     ...tableParm,
  //     queryType: tableParm.queryType || "All",
  //   };
  //   const startDate = dayjs(newParm["bookTime.greaterThanOrEqual"]);
  //   const endDate = dayjs(newParm["bookTime.lessThanOrEqual"] || dayjs());
  //   const diffInMonths = endDate.diff(startDate, "month");
  //   if (diffInMonths >= 3) {
  //     message.error("时间范围不能超过3个月");
  //     return;
  //   }
  //   exportOrder(newParm)
  //     .then((res) => {
  //       exportExcel(res.data);
  //     })
  //     .catch((e) => {
  //       if (e.response.data.type === "application/problem+json") {
  //         const fileReader = new FileReader();
  //         fileReader.readAsText(e.response.data, "utf-8");
  //         fileReader.onload = function () {
  //           const result = JSON.parse(fileReader.result as string);
  //           message.error(result.message);
  //         };
  //       }
  //     });
  // };
  useEffect(() => {
    //页面刷新回到顶部
    goTop();

    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...tableParm,
      key: new Date().getTime(),
    });
    const menuItem: any = menu.concat().filter((item: any) => {
      const pathOne = location.pathname.split("/")?.[1] || "";
      return item?.path === "/" + pathOne;
    });
    // const titleOne = menuItem.length > 0 ? menuItem[0].name : '';
    const titleTwo =
      menuItem.length > 0
        ? menuItem[0].children.concat().filter((item: any) => {
          const pathTwo = location.pathname.split("/")?.[2] || "";
          return item?.path === pathTwo;
        })?.[0]?.name
        : "";
    // add({ title: titleOne + '-' + titleTwo, url: location.pathname });
    add({ title: titleTwo, url: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="content">
      <div className="content_top">
        <div className="box">
          <Tabs
            size="small"
            type="editable-card"
            onChange={TabsOnChange}
            activeKey={activeKey}
            onEdit={onEdit}
            items={items}
            tabBarGutter={4}
            hideAdd
          />
        </div>
      </div>
      <div className="content_center"></div>
      <div className="content_search">
        {convertEnumToOptionType("purchaseOrderType") && (
          <SearchFor onSearch={(param: any) => onSearch(param)} />
        )}
      </div>
      <div className="tabs">
        <Tabs
          defaultActiveKey={orderType}
          activeKey={orderType}
          onChange={(res) => {
            tabSearch(res);
          }}
        >
          {orderTypeData.map((res) => (
            <TabPane tab={res.label} key={res.value}></TabPane>
          ))}
        </Tabs>

        <div className="uploadBox">
          <Space>
            {/* <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={goExport}
            >
              导出
            </Button> */}
            <ExportDataDialog
              columns={downloadColumns}
              dataSource={downloadAllEntities(tableData)}
              exportData={exportData}
              // onClickFun={() => { console.log('点击导出') }}
            />
            <ImportDataDialog
              columns={uploadColumns}
              buttonType="default"
              width="2000px"
              title="发货导入"
              remarks={
                <div style={{ color: "#ee2222", fontWeight: "bold" }}>
                  上传时请将下载的导入模版第一行备注删除！
                </div>
              }
              uploadEntity={(e: any) => batchShipment(e)}
              fieldListErrors={[]}
            />
          </Space>
        </div>
      </div>
      <div className="table-wrapper">
        <Row className="header">
          <Col className="col" span={4}>
            商品信息
          </Col>
          <Col className="col" span={2}>
            参加活动
          </Col>
          <Col className="col" span={1}>
            赠品标识
          </Col>
          <Col className="col" span={2}>
            颜色/尺码
          </Col>
          <Col className="col" span={2}>
            单价/数量
          </Col>
          <Col className="col" span={2}>
            实际单价/数量
          </Col>
          <Col className="col" span={1}>
            行小计
          </Col>
          <Col className="col" span={1}>
            赠送积分
          </Col>
          <Col className="col" span={1}>
            发货数量
          </Col>
          <Col className="col" span={1}>
            取消数量
          </Col>
          <Col className="col" span={2}>
            发货状态
          </Col>
          <Col className="col" span={2}>
            发货时间
          </Col>
          <Col className="col" span={2}>
            任选订单
          </Col>
          <Col className="col" span={2}>
            操作
          </Col>
        </Row>
        <Spin spinning={loading} wrapperClassName="spin">
          {tableData.length === 0 && (
            <div className="no-data-wrapper">
              <img src={imgNoData} alt=""></img>
            </div>
          )}
          {/* 订单 */}
          {tableData.map((item: any, index: number) => {
            return (
              <>
                <Row className="t-row" key={index}>
                  <div className="order-title">
                    <div className="order-row-left">
                      <div className="order-row-left-sonrow"></div>
                      <div className="order-row-left-sonrow">
                        <span>
                          订单编号: <Button
                            size="small"
                            type="link"
                            onClick={() => {
                              SetDetailShow(true);
                              SetDetailRow(item);
                              item.id && SetDetailId(String(item.id));
                            }}
                          >
                            {item.orderNo}{" "}
                          </Button>
                          <Button
                            size="small"
                            type="link"
                            onClick={() => copyToClipboard(item.orderNo)}
                          >
                            复制
                          </Button>{" "}
                        </span>
                        <span>
                          订单类型:{" "}
                          {getEnumValueDesc(
                            "purchaseOrderType",
                            item.purchaseOrderType || ""
                          ) || "-"}
                        </span>
                        <span>
                          {item?.hasActivity && <Tag color="#ee2222">{item.activityName}</Tag>}
                        </span>
                      </div>
                      <div className="order-row-left-sonrow">
                        <span>下单时间：{item.bookTime}</span>
                        <span>付款时间：{item.payTime}</span>
                        <span>
                          买家授权码: {item.buyer?.buyerCode}{" "}
                          <Button
                            size="small"
                            type="link"
                            onClick={() =>
                              copyToClipboard(item?.buyer?.buyerCode || "")
                            }
                          >
                            复制
                          </Button>
                        </span>
                      </div>
                      {item.orderState === "CANCEL" && (
                        <div className="order-row-left-sonrow order-row-left-sonrow-red">
                          <span>
                            取消原因：
                            <text>
                              {internationalList(item.closeReasonDesc || "")}
                            </text>
                          </span>
                          <span>
                            取消时间：<text>{item.closeTime}</text>
                          </span>
                          <span>
                            其他原因：
                            <text>
                              {internationalList(item.otherReasonDesc || "")}
                            </text>
                          </span>
                        </div>
                      )}
                      <div className="order-row-left-sonrow">
                        <span>
                          收货人姓名/电话：{item.address?.consigneeName} /{" "}
                          {item.address?.mobile}
                        </span>
                        <span>
                          收货人信息：{item.address?.provinceName + " "}
                          {item.address?.cityName + " "}
                          {item.address?.districtName + " "}{" "}
                          {item.address?.address}
                        </span>
                      </div>
                    </div>
                    <div
                      className="order-row-center"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <p style={{ color: "#1890ff" }}>
                        {" "}
                        {getEnumValueDesc(
                          "purchaseOrderStatusLabel",
                          item.statusLabel || ""
                        )}
                        {getEnumValueDesc(
                          "purchaseOrderStatus",
                          item.orderState || ""
                        ) || "-"}
                      </p>
                      <p>
                        <span>实收金额:</span> {currency?.symbol}{" "}
                        {item.payAmount || 0}
                      </p>
                      <p>
                        <span>合计数量:</span> {item.totalQuantity || 0}
                      </p>
                    </div>
                    <div className="order-row-right">
                      <p>
                        {(item.orderState === "OUT_OF_STOCK" ||
                          item.orderState === "SHIPPED") &&
                          !item.shipCompleted ? (
                          <Button
                            ghost
                            type="primary"
                            size="small"
                            style={{ marginRight: "3px" }}
                            onClick={() => {
                              setOrderData(item);
                              setIsShip(true);
                            }}
                          >
                            发货
                          </Button>
                        ) : (
                          ""
                        )}
                      </p>
                      {/* <p>
                      {item.orderState === 'TO_BE_REVIEWED' ?
                        <Popconfirm
                          title="确定审核通过吗"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        >
                          <Button ghost type="primary" style={{ marginRight: '3px' }} size="small" onClick={() => auditPurchaseOrderPost(item.id)}>审核</Button>
                        </Popconfirm> : ''}
                    </p> */}
                      <p>
                        {((['OUT_OF_STOCK', 'TO_BE_PAID', 'SHIPPING'].indexOf(item.orderState) > -1 || (item.orderState === 'SHIPPED' && item.statusLabel === 'Part'))) ?
                          (!item?.hasActivity ? <Button ghost danger type="primary" size="small" onClick={() => openCancelDialog(item.id)}>{
                            (item.orderState === 'SHIPPED' && item.statusLabel === 'Part') ? ('未发货商品取消订单') : '取消订单'
                          }</Button> : <Button ghost danger type="primary" size="small" onClick={() => openCancelDialog(item.id)}>取消订单</Button>) : ''}
                        <Button
                          ghost
                          type="primary"
                          size="small"
                          style={{ marginRight: "3px" }}
                          onClick={() => {
                            let list: any = [];
                            item.lines.forEach((sitem: any) => {
                              if (
                                sitem.payDetail &&
                                sitem.payDetail.length > 0
                              ) {
                                list.push(
                                  Object.assign(sitem, {
                                    totalPayAmount: item.payAmount,
                                  })
                                );
                              }
                            });
                            setPaymentDetails(list);
                            setOrderId(item.id);
                            setKey(++key);
                            setOpenPayDetail(true);
                          }}
                        >
                          货款明细
                        </Button>
                      </p>
                    </div>
                  </div>
                  {/* 商品列表 */}
                  {item.isOpen && item.lines.length > 1
                    ? item.lines.map((sonItem: any) => {
                      return orderRead(sonItem);
                    })
                    : item.lines.map((sonItem: any, sonindex: number) => {
                      return sonindex === 0 ? orderRead(sonItem) : "";
                    })}
                  {/* 收缩展开 */}
                  {item.lines.length > 1 ? (
                    <Row
                      className="order-row fold"
                      onClick={() => setDataIsOpen(index, item.isOpen)}
                    >
                      <span>
                        {item?.isOpen ? "收起" : "查看更多"}{" "}
                        {item?.isOpen ? true : false}
                      </span>
                    </Row>
                  ) : (
                    ""
                  )}
                </Row>
              </>
            );
          })}
        </Spin>
        {/* 分页器 */}
        <div className="btm-zw"></div>
        {paginationTotal > 0 && (
          <div className="pagination">
            <Pagination
              onChange={pageOnChange}
              pageSizeOptions={["10", "20", "30", "50"]}
              total={paginationTotal}
              showSizeChanger
              size="small"
              showTotal={(total, range) =>
                `第${range[0]}-${range[1]}条/ 总共${total} 条`
              }
              defaultPageSize={10}
              current={tableParm.page + 1}
            />
          </div>
        )}
      </div>

      {/* 发货弹框 */}
      {isShip ? (
        <OrderShipModal
          orderData={orderData}
          isShip={isShip}
          onCancel={() => {
            setIsShip(false);
            getTableData(tableParm);
          }}
        />
      ) : (
        ""
      )}
      <Drawer
        visible={detailShow}
        onClose={() => SetDetailShow(false)}
        width={"84vw"}
      >
        <OrderDetails detailRow={detailRow} id={detailId} listType={"order"} />
      </Drawer>

      {/* 取消订单 */}

      <ModalForm
        {...formItemLayout}
        title="取消订单"
        width={420}
        form={form}
        preserve={false}
        visible={toCancelDialog}
        modalProps={{
          onCancel: () => settoCancelDialog(false),
        }}
        onFinish={async (data: any) => {
          const obj = {
            remark: data.remark,
            code: data.code,
            desc: closeDesc || "",
          };
          orderCancel(orderId || "", obj)
            .then((e: any) => {
              settoCancelDialog(false);
              if (e.status < 305) {
                message.success("操作成功");
              } else {
                setForceCancelObj(obj);
                setForceCancel(true);
                message.error(e.statusText);
              }
            })
            .catch((e: any) => {
              settoCancelDialog(false);
              message.error(e.response.data.message);
              setForceCancelObj(obj);
              setForceCancel(true);
              return false;
            });
        }}
      >
        <SelectSearch
          optionKey="desc"
          fetchOptionKey={setCloseDesc}
          fetchFunc={fetchOrderCancelList}
          name={"code"}
          width="md"
          label="取消原因"
          labelValue="desc"
          optionValue="code"
          defaultParams={""}
          searchParams={""}
        />
        <ProFormTextArea name="remark" allowClear={true} label="取消备注" />
      </ModalForm>

      {/* 取消订单 */}

      <ModalForm
        title="是否强制取消订单"
        width={420}
        layout="horizontal"
        preserve={false}
        visible={forceCancel}
        modalProps={{
          onCancel: () => setForceCancel(false),
        }}
        onFinish={async (data: any) => {
          orderCancel(orderId || "", {
            ...forceCancelObj,
            forceCancel: true,
          })
            .then((e: any) => {
              if (e.status < 305) {
                setForceCancel(false);
                message.success("操作成功");
              } else {
                message.error(e.statusText);
              }
            })
            .catch((e: any) => {
              message.error(e.response.data.message);
              return false;
            });
        }}
      >
        <p>该订单已推送中台，确认旺店通/中台已取消后强制取消订单？</p>
      </ModalForm>

      {/* 款项明细 */}
      <PayDetailDialog
        visible={openPayDetail}
        key={key}
        orderId={orderId}
        paymentDetails={paymentDetails}
        onCancel={() => {
          setOpenPayDetail(false);
        }}
      />
    </div>
  );
};

export default Table;
