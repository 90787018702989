import { FC, ReactNode, Key, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { Button, Form, Input, message, Tag, Image } from "antd";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity, unBind, updateCustomer } from "./customer.reducer";
import { RouteComponentProps } from "@reach/router";
import CustomerEdit from './customer-edit';
import { ModalForm, ProFormGroup, ProFormSelect } from "@ant-design/pro-form";
import CustomerDetails from './customer-details'
import { fetchLevelList, fetchStoreList } from "../unit/unit.reducer";
import SelectSearch from "components/select-search";
import { dealerExport, getTagList, getRegionalHeadList, addRegionalHeadPeople, updateIdentityTags } from './customer.reducer'
import { Customer, RelationshipState } from "model/dealer/customer.model";
import { DownloadOutlined } from "@ant-design/icons";
import { exportExcel } from "components/tool";
import DecryptText from "./detailtables/decryptText";
import { internationalList } from "components/IntelFormText";
import { useEnumValue } from "common/enum/use-enum-value";

export interface CustomerListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}


const CustomerList: FC<CustomerListProps> = (props) => {
  let [key, setKey] = useState<any>(0);
  let [rowKeys, setSelectedRowKeys] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<any>({});
  const { getEnumValueDesc } = useEnumValue();


  const columns: ProColumns<Customer>[] = [
    {
      title: '授权号',
      dataIndex: 'account',
      valueType: 'text',
      width: 50
    },

    {
      title: '用户信息',
      dataIndex: 'name',
      valueType: 'text',
      width: 150,
      render: (text, row: any) => {
        return <div className="user-info">
          <Image src={row.avatar ? row.avatar : 'https://images-test.merrige.cn/public/avatar/default.png'} fallback='https://images-test.merrige.cn/public/avatar/default.png' alt="" className="avator"></Image>
          <div>
            <p>{row.name}{!!row.isMerrigeChild && <Tag style={{marginLeft:'12px'}} color="#ee2222">美二代</Tag>}</p>
            {!!row.customerReturnType && <p><Tag color="green">{getEnumValueDesc('customerReturnType', row.customerReturnType || '')}</Tag></p>}
            <p>登陆账号：{row.userAccount || '-'}</p>
            <p>登录手机号：{row.registerPhone || '-'}<DecryptText title='手机号' id={row.id} data={row?.encryptRegisterPhone} /></p>
            <p>手机号：{row.phone || '-'}<DecryptText title='手机号' id={row.id} data={row?.encryptPhone} /></p>
            <p>证件号：{row.credentialsNo || '-'}<DecryptText title='身份证号' id={row.id} data={row?.encryptCredentialsNo} /></p>
            {row?.identityTagDesc ? <p><Tag color="#1890FF">{row?.identityTagDesc} </Tag></p> : ''}
          </div>
        </div>
      }
    },
    {
      title: '当前等级',
      dataIndex: 'relationshipList',
      valueType: 'text',
      width: 60,
      render: (text, row: any) => {
        return row.relationshipList?.map((item: any) => {
          return <p className="fontWeight">{item.storeShortName || internationalList(item.storeName)} : {item.levelShortName || internationalList(item.levelName)}</p>
        })
      }
    },
    {
      title: '历史授权号',
      dataIndex: 'relationshipList',
      valueType: 'text',
      width: 70,
      render: (text, row: any) => {
        return row.relationshipList?.map((item: any) => {
          return <p className="fontWeight">{item.storeShortName || '-'} : {item.code || '-'}</p>
        })
      }
    },
    {
      title: '邀请人',
      dataIndex: 'relationshipList',
      valueType: 'text',
      width: 150,
      render: (text, row: any) => {
        return row.relationshipList?.map((item: any) => {
          return <p >{item.storeShortName} : {item.parentCustomerCode || '-'} / {item.parentCustomerName}</p>
        })
      }
    },
    {
      title: '所属6S',
      dataIndex: 'relationshipList',
      valueType: 'text',
      width: 150,
      render: (text, row: any) => {
        return row.relationshipList?.map((item: any) => {
          return <p> {item.storeShortName} : {item.belongCustomerCode || '-'} / {item.belongCustomerName || ''} </p>
        })
      }
    },
    {
      title: '区域负责人',
      dataIndex: 'regionalHeadPeopleDto',
      valueType: 'text',
      width: 70,
      render: (text, row: any) => {
        if (row?.regionalHeadPeopleDto) {
          return row?.regionalHeadPeopleDto?.regionalName + '-' + row?.regionalHeadPeopleDto?.name + '-' + row?.regionalHeadPeopleDto?.customerNo
        }
      }
    },
    {
      title: '状态',
      dataIndex: 'state',
      valueType: 'text',
      width: 50,
      render: (text, row: any) => {
        return row.relationshipList?.map((item: any) => {
          return <p> {item.storeShortName} :  {RelationshipState[item.state] || ''}  </p>
        })
      }
    }
  ];

  const searchFields: SearchField[] = [{
    label: 'ID',
    name: 'id.equals',
  }, {
    label: '姓名',
    name: 'name.equals',
  }, {
    label: '手机号',
    name: 'phone.equals',
  }, {
    label: '身份证号',
    name: 'credentialsNo.equals',
  }, {
    label: '用户授权号',
    name: 'account.equals',
  }, {
    label: '历史授权号',
    name: 'code.equals',
  }, {
    label: '邀请人授权号',
    name: 'parentCode.equals',
  }, {
    label: '所属6S授权号',
    name: 'belongCustomerCode.equals',
  }, {
    label: '所属6S姓名',
    name: 'belongCustomerName.contains',
  }];

  const fundButtonRender: any = (dom: ReactNode, record: any): ReactNode => ((record.levelId && record.levelType) ? <div style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => {
    if (record.customer.userId) {
      let path = `${window.location.origin}/financial/fund?userId.equals=${record.customer.userId}&storeId.equals=${record.storeId}`
      window.open(path, 'target')
    } else {
      message.error('缺少userId必要参数');
    }
  }}>资金</div> : '')

  // 导出
  const goExport = () => {
    let newParm = {};
    dealerExport(newParm)
      .then((res) => {
        exportExcel(res.data);
      })
      .catch((e: any) => {
        if (e.response.data.type === "application/problem+json") {
          const fileReader = new FileReader();
          fileReader.readAsText(e.response.data, "utf-8");
          fileReader.onload = function () {
            const result = JSON.parse(fileReader.result as string);
            message.error(result.message);
          };
        }
      });
  };

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          request={async (params) => {
            const data: any = await fetchStoreList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          name={['storeId.equals']}
          placeholder="店铺"
        />
        <div className="wd10" />
        <SelectSearch required={false} name="levelId.equals" placeholder="等级" labelValue="levelName" defaultParams='' fetchFunc={fetchLevelList} searchParams='levelName.contains' />
        <div className="wd10" />
        <ProFormSelect
          request={async (params) => {
            const data: any = await getTagList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          name={'tagType.equals'}
          placeholder="身份标签"
        />
        <div className="wd10" />
        <ProFormSelect
          style={{ 'width': '200px' }}
          mode="multiple"
          request={async (params) => {
            const data: any = await getRegionalHeadList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.regionalName + '-' + item.name
            }))
          }}
          name={'regionalHeadPeople.in'}
          placeholder="区域负责人"
        />
        <div className="wd10" />
        <SearchBox
          fields={searchFields}
          width={210}
        />

      </Input.Group>
    </Form.Item>
  );


  const additionActions = [
    <ModalForm
      width={400}
      title="添加或修改区域负责人"
      trigger={<Button type="primary" icon={<></>}>添加或修改区域负责人</Button>}
      modalProps={{ destroyOnClose: true, }}
      onFinish={async (values) => {
        if (rowKeys.length === 0) {
          message.error('请选择列表')
          return false
        }
        let userRegionDto: any = []
        rowKeys.forEach((item: any) => {
          userRegionDto.push({
            id: item,
            regionalHeadPeopleId: values.regionalHeadPeopleId
          })
        })


        let data = {
          userRegionDto: userRegionDto,
        }
        addRegionalHeadPeople(data).then((e: any) => {
          setKey(++key)
          setSelectedRowKeys([])
          return true;
        }).catch((e: any) => {
          message.error(e.response.data.message);
          return false
        })
        return false;
      }}
    >
      <ProFormGroup size="middle">
        {/* <SelectSearch optionKey="name" togetherParams={{ 'reverseTag.equals': false }} width={'md'} label="" required={false} name={'tagId'} placeholder="标签" labelValue="name" defaultParams={'id.equals'} fetchFunc={getTagList} searchParams='name.contains' /> */}
        <ProFormSelect
          width={'md'}
          name='regionalHeadPeopleId'
          request={async (params) => {
            const data: any = await getRegionalHeadList();
            data.unshift({
              id: '',
              name: '',
              regionalName: '无'
            })
            return data.map((item: any) => ({
              value: item.id,
              label: item.regionalName === '无' ? '无' : item.regionalName + '-' + item.name
            }))
          }}
          placeholder="请选择区域负责人"
        />
      </ProFormGroup>

    </ModalForm>,
    <ModalForm
      width={400}
      title="添加或修改身份标签"
      trigger={<Button type="primary" icon={<></>}>添加或修改身份标签</Button>}
      modalProps={{ destroyOnClose: true, }}
      onFinish={async (values) => {
        delete filterParams.page
        delete filterParams.size
        if (rowKeys.length === 0) {
          message.error('请选择列表')
          return false
        }

        let data = {
          ...filterParams,
          tagId: values.tagId,
          idList: [...rowKeys],
          isAll: false
        }
        updateIdentityTags(data).then((e: any) => {
          setKey(++key)
          setSelectedRowKeys([])
          return true;
        }).catch((e: any) => {
          message.error(e.response.data.message);
          return false
        })
        return false;
      }}
    >
      <ProFormGroup size="middle">
        {/* <SelectSearch optionKey="name" togetherParams={{ 'reverseTag.equals': false }} width={'md'} label="" required={false} name={'tagId'} placeholder="标签" labelValue="name" defaultParams={'id.equals'} fetchFunc={getTagList} searchParams='name.contains' /> */}
        <ProFormSelect
          width={'md'}
          name='tagId'
          request={async (params) => {
            const data: any = await getTagList();

            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          placeholder="请选择身份标签"
        />
      </ProFormGroup>

    </ModalForm>,
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      onClick={goExport}
    >
      导出
    </Button>
  ];

  return (
    <>
      <ListViewPage<Customer>
        actions={additionActions}
        key={key}
        rowSelection={{
          type: 'checkbox',
          columnWidth: 26,
          onChange: (selectedRowKeys: Key[], selectedRows, checkedTypes) => {
            setSelectedRowKeys(selectedRowKeys);
          }
        }}
        columns={columns}
        quickFilterPanel={quickFilterPanel}
        fetchSearchParams={setFilterParams}
        hideCreateButton
        hideDeleteButton
        hideEditButton
        editPage={(params) => <CustomerEdit id={params.id} text={params.text} />}
        detailPage={<CustomerDetails navigate={props.navigate} columns={columns} />}
        rowActions={[fundButtonRender]}
        hideActionColumn={true}
        showSearchButton
        detailsIndex={0}
        {...props}
      >
        {props.children}
      </ListViewPage>
    </>
  );
};

const mapStateToProps = ({ dealerCustomer }: RootState) => ({
  entity: dealerCustomer.entity,
  entities: dealerCustomer.entities,
  loading: dealerCustomer.loading,
  updateSuccess: dealerCustomer.updateSuccess,
  total: dealerCustomer.total,
  createSuccess: dealerCustomer.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateCustomer,
  unBind
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(CustomerList)