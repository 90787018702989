import ProForm, { ModalForm, ProFormDigit, ProFormSelect } from "@ant-design/pro-form";
import { Form, message } from "antd";
import { fetchBalance, fetchBankCard, fetchWithdrawalList, passwordControl } from "./withdrawal.reducer";
import ProTable from "@ant-design/pro-table";
import { useState } from "react";
import Title from "antd/lib/typography/Title";

const WithdrawalList: any = () => {
  const [form] = Form.useForm();
  const [withdrawalRecordData, setWithdrawalRecordData] = useState([]);
  const [bankCardInfo, setBankCardInfo] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [token, setToken] = useState<any>({});
  const [payUserIdData, setPayUserIdData] = useState('');
  const withdrawalRecordColumns = [{
    title: 'id',
    dataIndex: 'id',
    width: 100,
  }, {
    title: '公司账号code',
    dataIndex: 'customerCode',
    width: 100,
  }, {
    title: '公司账号名',
    dataIndex: 'customerName',
    width: 100,
  }, {
    title: '提现金额',
    dataIndex: 'amount',
    width: 100,
  }, {
    title: '提现时间',
    dataIndex: 'payDateTime',
    width: 100,
  }, {
    title: '状态',
    dataIndex: 'status',
    width: 100,
  },];
  return (
    <div className="withdrawalBox">
      <Title level={5}>提现申请</Title>
      <ProForm
        submitter={{
          searchConfig: { submitText: '发起提现申请' },
          render: (_, dom) => dom.pop(),
        }}
        onFinish={async (data) => {
          if (data.withdrawalMoney > data.money) {
            message.error('提现金额 不可大于 可提现金额！')
          } else {
            passwordControl({
              passwordUseType: 4,
              channelNo: 1,
              userType: bankCardInfo.userType,
              customNo: data.customerCode,
              amount: data.withdrawalMoney
            }).then(e => {
              const res = e.data.data;
              setToken({ ...res, payUserId: payUserIdData, bankNo: data.bankNo, customerCode: data.customerCode, totalAmount: data.withdrawalMoney });
              setVisible(true);
              // oidPartner //商户号
              // :
              // "402312210000016010"
              // passwordElementToken //用于唤醒密码控件的token
              // :
              // "2b770241f8490bb6ec234b15a7d20584"
              // retCode
              // :
              // "0000"
              // retMsg
              // :
              // "交易成功"
            })
            // setVisible(true);
          }
        }}
        form={form}
      >
        <ProFormSelect
          name="customerCode"
          options={[{
            label: "美人计",
            value: 'MC001',
            payUserId: 'MRG20231227',
          }, {
            label: "各美其美",
            value: 'MC002',
            payUserId: 'GMQM20231228',
          }, {
            label: "慧库",
            value: 'MC003',
            payUserId: 'HK20240227',
          }, {
            label: "私语",
            value: 'SY001',
            payUserId: 'SY001',
          }]}
          label='账号'
          fieldProps={{
            onChange: (data, option: any) => {
              const payUserId = option.payUserId || '';
              setPayUserIdData(payUserId);
              if (!!data) {
                fetchBalance(data).then((e: any) => {
                  form.setFieldsValue({
                    money: e || 0
                  })
                });
                fetchWithdrawalList(data).then((e: any) => {
                  setWithdrawalRecordData(e || []);
                });
                fetchBankCard(data).then((e: any) => {
                  setBankCardInfo(e);
                });
              } else {
                setWithdrawalRecordData([]);
                form.setFieldsValue({
                  money: 0
                })
              };
            }
          }}
        />
        <ProFormSelect
          name="bankNo"
          options={bankCardInfo?.bankInfoList ? bankCardInfo.bankInfoList.map((e: any) => ({ label: e.bankName, value: e.bankNo })) : []}
          rules={[{ required: true }]}
          label='银行卡'
        />
        <ProFormDigit
          width="md"
          name="money"
          initialValue={0}
          label="可提现金额"
          disabled
        />
        <ProFormDigit
          width="md"
          name="withdrawalMoney"
          rules={[{ required: true }]}
          label="提现金额"
        />
      </ProForm>
      <div className="withdrawalTabBox">
        <Title level={5}>提现记录</Title>
        <ProTable<any>
          columns={withdrawalRecordColumns}
          rowKey="id"
          dataSource={withdrawalRecordData}
          options={false}
          search={false}
          pagination={false}
        />
      </div>
      <ModalForm
        title={'提现信息验证'}
        visible={visible}
        onVisibleChange={(e) => !e && setVisible(false)}
        modalProps={{
          destroyOnClose: true,
        }}
        preserve={false}
        onFinish={async (data: any) => {
          return true;
        }}
      >
        <div>
          <iframe title='提现' id="mapPage" width="330px" height="700px"
            src={`https://app.merrige.cn/H5/index.html#/pages/document/lianlianpay?token=${token.passwordElementToken}&oidPartner=${token.oidPartner}&payUserId=${token.payUserId}&bankNo=${token.bankNo}&customerCode=${token.customerCode}&totalAmount=${token.totalAmount}`}>
          </iframe>
        </div>
      </ModalForm>
    </div>
  );
};

export default WithdrawalList;