import { FC, ReactNode, useState } from "react";
import { Form, Input, Tooltip, Tabs, message, Divider, Button } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import FlowTemplateDetail from "./bankStatement-details";
import { deleteEntity, getEntities, getEntity, flowDistribution, importBankFlowEntities, updateEntity, checkFlow } from './bankStatement.reducer';
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import FlowTemplateEdit from "./bankStatement-edit";
import FlowDistribution from './flowDistribution'
import { BankStatement } from "model/financial/bankStatement.model";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import moment from 'moment';
import ImportDataDialog from "upload/import-data-dialog";

export interface BankStatementListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<BankStatement>[] = [{
  title: 'ID',
  dataIndex: 'id',
  valueType: 'text',
  width: 60

}, {
  title: '银行名称',
  dataIndex: 'bankName',
  width: 150
},
{
  title: '银行账号',
  dataIndex: 'bankAccount',
  ellipsis: true
}, {
  title: '收款金额',
  dataIndex: 'receiptAmount',
  width: 100
}, {
  title: '到账日期',
  dataIndex: 'arrivalDate',
  ellipsis: true,
  valueType: 'dateTime'
}, {
  title: '付款人姓名',
  dataIndex: 'counterpart',
  ellipsis: true
}, {
  title: '交易流水号',
  dataIndex: 'flowNo'
}, {
  title: '用途说明',
  dataIndex: 'useDescription',
  render: (text) => {
    return <p className="hideone"><Tooltip title={text}>{text} </Tooltip></p>
  }
}, {
  title: '备注',
  dataIndex: 'remark',
  width: 120,
  hideInTable: true
},
{
  title: '付款人授权码',
  dataIndex: 'payerCode',
  valueType: 'text',

},
{
  title: '收款人授权码',
  dataIndex: 'payeeCode',
  valueType: 'text',

}
];


export const tabStatusData = [
  {
    label: '未分配',
    value: 'Not',
  }, {
    label: '已分配',
    value: 'Allotted',
  }, {
    label: '已复核',
    value: 'Checked',
  }, {
    label: '全部',
    value: '',
  },
]

const BankStatementList: FC<BankStatementListProps> = (props) => {

  const { importBankFlowEntities } = props;

  const searchFields = [{
    label: '银行名称',
    name: 'bankName.contains',
  }, {
    label: '交易对方',
    name: 'counterpart.contains',
  }, {
    label: '对方银行账号',
    name: 'otherBankNo.contains',
  }, {
    label: '付款人授权码',
    name: 'payerCode.equals',
  }, {
    label: '收款人授权码',
    name: 'payeeCode.equals',
  }];
  const [flowStatus, setFlowStatus] = useState('Not');
  let [key, setKey] = useState(0);
  const [record, setRecord]: any = useState({});
  // 充值弹框
  const [open, setOpen] = useState(false);
  const showModal = async (record: any) => {
    setRecord({ ...record })
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
    setRecord({})
  };
  // 详情弹框
  const [detailOpen, setDetailOpen] = useState(false);
  const showDetailDrawer = async (record: any) => {
    setRecord({ ...record })
    setDetailOpen(true);
  };
  const hideDetailDrawer = () => {
    setDetailOpen(false);
    setRecord({})
  };


  // 流水分配
  const flowDisButtonRender: any = (dom: ReactNode, record: BankStatement): ReactNode => {
    return (
      flowStatus === 'Not' || record.allotStatus === 'Not' ?
        <Tooltip title="流水分配">
          <Button size="small" type='link' onClick={() => showModal(record)}>流水分配 </Button>  <Divider type='vertical' />
        </Tooltip> : ''
    )
  };


  // 复核
  const checkButtonRender: any = (dom: ReactNode, record: BankStatement): ReactNode => {
    return (
      flowStatus === 'Allotted' || record.allotStatus === 'Allotted' || record.allotStatus === 'Checked' ?
        <Tooltip title="复核">
          <Button size="small" type='link' onClick={() => showDetailDrawer(record)}> {record.allotStatus === 'Checked' ? '复核详情' : '复核'}</Button>
        </Tooltip> : ''
    )
  };

  // 删除
  const hideDeleteButtonRender: any = (dom: ReactNode, record: BankStatement): ReactNode => {
    let id = record.id
    return (
      flowStatus === 'Not' || record.allotStatus === 'Not' ?
        <Tooltip title="删除">
          <span style={{ color: "#ff4d4f" }} onClick={() => {
            props.deleteEntity(id)
          }}>删除</span>
        </Tooltip> : ''
    )
  };


  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const uploadColumns: ProColumns<any>[] = [
    {
      title: '付款授权号',
      dataIndex: 'payerCode',
      valueType: 'text',
    },
    {
      title: '银行名称',
      dataIndex: 'bankName',
      valueType: 'text',
    },
    {
      title: '银行账号',
      dataIndex: 'bankAccount',
      valueType: 'text',

    },
    {
      title: '收款金额',
      dataIndex: 'receiptAmount',
      valueType: 'text'

    },
    {
      title: '到账日期',
      dataIndex: 'arrivalDate',
      valueType: 'text',
      render: (text: any) => {
        let isValid = moment(new Date(text)).isValid()
        let time = isValid ? moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss') : text
        return <p>{time}</p>
      }
    },
    {
      title: '付款人姓名',
      dataIndex: 'counterpart',
      valueType: 'text',
    },
    {
      title: '交易流水号',
      dataIndex: 'flowNo',
      valueType: 'text',

    },
    {
      title: '用途说明',
      dataIndex: 'useDescription',
      valueType: 'text',

    },
    {
      title: '备注',
      dataIndex: 'remark',
      valueType: 'text'
    }
  ]


  const additionActions = [
    <Tabs defaultActiveKey={flowStatus} className='product-tabs' activeKey={flowStatus}
      onChange={
        res => { setFlowStatus(res); setKey(++key) }}>
      {tabStatusData.map(res => <TabPane tab={res.label} key={res.value}></TabPane>)}
    </Tabs>,
    <ImportDataDialog key={key} columns={uploadColumns}
      buttonType="default"
      width='2000px'
      title="导入银行流水"
      uploadEntity={(e: any) => {
        const arr = e.concat().map((item: any) => {
          const text = item.arrivalDate;
          let isValid = moment(new Date(text)).isValid()
          // let time =isValid ? moment(new Date(text)).add(43, 'second').format('YYYY-MM-DD HH:mm:ss') : text
          let time = isValid ? moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss') : text
          return {
            ...item,
            arrivalDate: time
          }
        })
        importBankFlowEntities(arr)
      }}
    />
  ];
  return (<div key={key}>
    <ListViewPage<BankStatement>
      columns={columns}
      key='id'
      hideDeleteButton
      filters={{ 'allotStatus.equals': flowStatus ? flowStatus : '' }}
      actions={additionActions}
      rowActions={[flowDisButtonRender, checkButtonRender, hideDeleteButtonRender]}
      quickFilterPanel={quickFilterPanel}
      hideEditButton
      detailPage={<FlowTemplateDetail navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      scrollConfig={{ x: 1500 }}
      editPage={(params: any) => <FlowTemplateEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
    {/* 流水分配 */}
    <FlowDistribution visible={open} record={record} onCancel={() => {
      hideModal();
    }} onRecharge={() => {
      hideModal();
      message.success('流水分配成功')
      setKey(++key)
    }} />

    {/* 详情弹框 */}
    <FlowTemplateDetail visible={detailOpen} record={record} onCancel={() => {
      hideDetailDrawer();
    }} onSubmit={(id: number) => {
      checkFlow(id).then(e => {
        setKey(++key)
        hideDetailDrawer();
      }).catch((e) => {
        message.error(e.response.data.message);
      })

    }} />
  </div>
  );
};

const mapStateToProps = ({ bankStatement }: RootState) => ({
  entity: bankStatement.entity,
  entities: bankStatement.entities,
  loading: bankStatement.loading,
  updateSuccess: bankStatement.updateSuccess,
  createSuccess: bankStatement.createSuccess,
  total: bankStatement.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  flowDistribution,
  importBankFlowEntities,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementList);